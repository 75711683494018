<template>
  <div v-if="loaded">
    <div class="row mb-3">
      <div class="col">
        <div
          class="card"
          v-for="api_key in company.api_keys"
          :key="api_key.api_key"
        >
          <div class="card-header">
            <h3 class="card-title">Your Integration Key</h3>
          </div>
          <div class="card-body">
            <div class="d-flex mb-3">
              <div class="input-group me-2">
                <input
                  class="form-control"
                  :type="visible ? 'text' : 'password'"
                  readonly=""
                  :value="api_key.api_key"
                  @focus="$event.target.select()"
                  @copy.prevent="copyKey(api_key.api_key)"
                />
                <a
                  href="#"
                  class="btn btn-icon"
                  @click.prevent="visible = !visible"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-eye"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    v-if="!visible"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="2"></circle>
                    <path
                      d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                    ></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-eye-off"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    v-else
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="3" y1="3" x2="21" y2="21"></line>
                    <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path>
                    <path
                      d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"
                    ></path>
                  </svg>
                </a>
              </div>
              <a
                href="#"
                @click.prevent="copyKey(api_key.api_key)"
                class="btn btn-primary me-2"
              >
                <span v-if="copied">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                  Copied!
                </span>
                <span v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-link"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"
                    ></path>
                    <path
                      d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"
                    ></path>
                  </svg>
                  Copy Key
                </span>
              </a>
              <a
                href="#"
                @click.prevent="refreshAPIKey(true)"
                class="ms-auto btn btn-outline-danger"
              >
                Regenerate Key
              </a>
            </div>

            <p>
              <b>
                Use your API key to connect your account to third party
                services.
              </b>
              Only share your API key with trusted integrations, as it can be
              used to access data in your BusinessChatter account.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h3>Supported Integrations</h3>
      </div>
    </div>

    <div class="row">
      <div
        class="col-md-4"
        v-for="integration in integrations"
        :key="`integration-${integration.id}`"
      >
        <div class="card">
          <div class="card-header d-flex">
            <h3 class="card-title">Zapier</h3>
            <a
              href="#"
              @click.prevent="openIntegration(integration.url)"
              class="ms-auto btn btn-sm btn-outline"
              v-if="
                !companyUsingIntegration(integration.slug) &&
                $auth.planIncludes('reviews-integrations', $auth.highestPlan)
              "
            >
              Connect
            </a>
            <a
              href="#"
              @click.prevent
              class="ms-auto btn btn-sm btn-outline-success disabled"
              v-else-if="companyUsingIntegration(integration.slug)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-check"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              Connected
            </a>
            <a
              href="#"
              @click.prevent
              class="ms-auto btn btn-sm btn-outline disabled"
              v-else
            >
              Upgrade to Connect
            </a>
          </div>
          <div class="card-body">
            <div class="text-center mb-3">
              <img src="@/assets/zapier-logo.svg" style="max-width: 125px" />
            </div>
            <ul>
              <li
                v-for="(benefit, idx) in integration.benefits"
                :key="`integration-${integration.id}-benefit-${idx}`"
              >
                {{ benefit }}
              </li>
            </ul>
            <h4>Benefits</h4>
            <ul class="list-group list-group-flush mb-3">
              <li
                class="list-group-item d-flex align-items-center"
                v-for="(permission, idx) in integration.permissions.yes"
                :key="`integration-${integration.id}-permission-${idx}`"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler me-2 icon-tabler-check"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M5 12l5 5l10 -10"></path>
                </svg>
                {{ permission }}
              </li>
            </ul>

            <div
              class="alert alert-warning"
              role="alert"
              v-if="integrationOutdated(integration.slug)"
            >
              <div class="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon alert-icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 9v2m0 4v.01"></path>
                    <path
                      d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h4 class="alert-title">Integration key is outdated.</h4>
                  <div class="text-muted">
                    Integration may not work correctly until it is updated.
                  </div>
                </div>
              </div>
            </div>

            <div class="d-grid">
              <a
                href="#"
                @click.prevent="openIntegration(integration.url)"
                class="btn btn-block btn-primary"
                v-if="
                  !companyUsingIntegration(integration.slug) &&
                  $auth.planIncludes('reviews-integrations', $auth.highestPlan)
                "
              >
                Connect
              </a>
              <a
                href="#"
                @click.prevent
                class="btn btn-outline-success disabled"
                v-else-if="companyUsingIntegration(integration.slug)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-check"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M5 12l5 5l10 -10"></path>
                </svg>
                Connected
              </a>
              <a
                href="#"
                @click.prevent
                class="btn btn-outline disabled"
                v-else
              >
                Upgrade to Connect
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";
import TrackingService from "@/services/TrackingService";

export default {
  name: "reputation.integrations",
  data() {
    return {
      company: null,
      loaded: false,
      visible: false,
      copied: false,
      popup: null,
      integrations: {
        zapier: {
          slug: "zapier",
          url: "https://zapier.com/engine/auth/start/MediaBrainsBusinessChatterCLIAPI@1.0.0/",
          id: 0,
          benefits: [
            "World's leading automation platform.",
            "Connect BusinessChatter to 2,000+ other applications.",
            "Setup in minutes.",
          ],
          permissions: {
            yes: [
              "Automatically request reviews from customers with triggers.",
              "Instant notifications when customers leave reviews with actions.",
            ],
          },
        },
      },
    };
  },
  computed: {
    apiKey() {
      if (this.company.api_keys.length > 0) {
        return this.company.api_keys[0].api_key;
      } else {
        return null;
      }
    },
  },
  methods: {
    getCompanyIntegration(slug) {
      let i = this.company.integrations.filter(
        (i) => i.integration.slug == slug
      );
      if (i.length > 0) {
        return i[0];
      } else {
        return null;
      }
    },
    companyUsingIntegration(slug) {
      return (
        this.company.integrations.filter((i) => i.integration.slug == slug)
          .length > 0
      );
    },
    integrationOutdated(slug) {
      let integration = this.getCompanyIntegration(slug);
      if (!integration) {
        return false;
      }
      return !integration.api_key.is_active;
    },
    openIntegration(url) {
      TrackingService.track("opened integration popup", {
        detail: "zapier",
      });

      let popup = window.open(
        url,
        "Connect Zapier",
        "width=550,height=600,menubar=no,status=no,resizable=no,toolbar=no"
      );
      this.popup = popup;
    },
    copyKey(api_key) {
      TrackingService.track("integration key copied");
      this.copied = true;
      navigator.clipboard.writeText(api_key).then(() => {});
      setTimeout(() => {
        this.copied = false;
      }, 1500);
      if (this.popup && !this.popup.closed) {
        this.popup.focus();
      } else if (this.popup.closed) {
        this.popup = null;
      }
    },
    async refreshCompany() {
      this.company = await ReputationManagementService.getCompany(
        this.$auth.activeCompany
      );
    },
    async refreshAPIKey(warn) {
      if (warn) {
        let confirm = await this.$bvModal.msgBoxConfirm(
          `Your API key may be in use with integrations. You will need to update the key in each of these integrations.`,
          {
            title: `Regenerate API key?`,
            size: "sm",
            okVariant: "outline-danger",
            okTitle: "Regenerate",
            cancelClasses: "me-auto",
            cancelTitle: "Cancel",
            cancelVariant: "primary",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        );
        if (!confirm) {
          return;
        }
      }
      TrackingService.track("refresh api key");
      await ReputationManagementService.refreshAPIKey(this.$auth.activeCompany);
      await this.refreshCompany();
    },
  },
  async mounted() {
    await this.refreshCompany();
    if (this.company.api_keys.length == 0) {
      await this.refreshAPIKey(false);
    }

    this.loaded = true;
  },
};
</script>
